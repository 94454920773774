import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import { Auth } from 'aws-amplify';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loggedUser = async () => {
  try {
    // console.log('User=>', Auth);
    const user = await Auth.currentAuthenticatedUser();
    return user;
    // console.log(user)
    // console.log(user.signInUserSession.idToken.payload["custom:gluu_groups"]);
    // if (user.signInUserSession.idToken.payload["custom:gluu_groups"] && user.signInUserSession.idToken.payload["custom:gluu_groups"].indexOf("upda_public_user") >= 0) {
    //   // console.log("public user");
    //   // history.push('/app')
    //   return "AUTHORIZED";
    // } else {
    //   // console.log("unAuth user");
    //   return "NOT_AUTHORIZED";
    // }
  }
  catch (ex) {
    // console.log(ex)
    return null;
  }

}
function* loginWithEmailPassword({ payload }) {
  // console.log(payload)
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    let loginUser = yield call(loggedUser);
    // console.log('loginUser.authUser=>', loginUser)
    if (loginUser) {
      // console.log(loginUser)
      const user_groups = loginUser.signInUserSession.idToken.payload["cognito:groups"] || [];
      //  console.log(user_groups)
      if (user_groups.indexOf("upda_public_user") >= 0) {
        localStorage.setItem('user_id', loginUser.username);
        localStorage.setItem('user_code', password);
        yield put(loginUserSuccess(loginUser));
        history.push('/app');
      } else {
        sessionStorage.clear();
        // localStorage.clear();
        localStorage.setItem('user_code', password);
        loginUser.username = 'un-authorized';
        yield put(loginUserSuccess(loginUser));
        history.push('/app');
        // history.push('/un-authorized');
      }
    } else {
      yield put(loginUserError("Error"));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      localStorage.setItem('user_id', registerUser.user.uid);
      yield put(registerUserSuccess(registerUser));
      history.push('/');
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  try {
    await Auth.signOut();
  }
  catch { }
  // const user_code = localStorage.getItem('user_code');
  history.push(`/user/logout`);
  // await auth
  //   .signOut()
  //   .then((authUser) => authUser)
  //   .catch((error) => error);
};

function* logout({ payload }) {
  const { history } = payload;
  localStorage.removeItem('user_id');
  try {
    yield call(logoutAsync, history);
    // localStorage.removeItem('user_code');
  } catch (error) { }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
